






















































































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BusyObject } from '@/models/Busy';
import { AgentExecutionFrequency } from '@/models/AgentExecutionFrequency';
import { StoredBrandQueriesService, StoredBrandQuery, User } from '@/api/braendz';
import { QueryMode } from '@/models/Query';
import { Locale, findLocale, locales } from '@/models/Locale';

@Component({
    components: {
    },
    model: {
        prop: "model",
        event: "visibilityChanged"
    }
})
export default class ConfigureStoredBrandQueryPopup extends Vue {
    // Member
    public valid = false;
    public selectedTab = 0;
    public busyStoredBrandQuery = new BusyObject<StoredBrandQuery>();

    public name = "";
    public description: string | null = null;
    public agentExecutionFrequency: AgentExecutionFrequency | null = null;

    public ownerMailAddress: string | null = null;
    public selectedAdditionalMailAddresses: string[] = [];
    public additionalMailAddresses: string[] = [];
    
    public selectedSize = 50;
    public sizes = [ 10, 25, 50, 75, 100 ];
        
    // Properties
    @Prop({ required: true })
    public model!: boolean;

    @Prop({required: true})
    public storedBrandQuery!: StoredBrandQuery;

    // Getter & Setter
    public get visible(): boolean {
        return this.model;
    }
    public set visible(value: boolean) {
        this.fireVisibilityChanged(value);
    }

    public get defaultName(): string {
        switch(this.storedBrandQuery.query.mode) {
            case QueryMode.Default:
                return this.storedBrandQuery.query.terms?.length === 1 ? this.storedBrandQuery.query.terms[0].value ?? '' : '';
            case QueryMode.ExactSearchIntersect:
            case QueryMode.ExactSearchUnion:
                return this.storedBrandQuery.query.terms?.map(i => i.field ? `${i.field} ${this.$t('configureStoredBrandQueryPopup.is')} ${i.value ?? this.$t('configureStoredBrandQueryPopup.empty')}` : `${i.value  ?? this.$t('configureStoredBrandQueryPopup.empty')}`).join(this.storedBrandQuery.query.mode === QueryMode.ExactSearchIntersect ? ` ${this.$t('configureStoredBrandQueryPopup.and')} ` : ` ${this.$t('configureStoredBrandQueryPopup.or')} `) ?? "";
        }
        return "";
    }

    public get userMailAddress(): string | null | undefined {
        return (this.$store.state.user as BusyObject<User>)?.object?.mailAddress;
    }

    public selectedNotificationLanguage: Locale | null = null;

    public get locales(): Locale[] {
      return locales;
    }

    // Watcher:
    @Watch('storedBrandQuery')
    public onStoredBrandQueryChanged() {
        this.reset();
    }
    @Watch('model')
    public onModelChanged(value: boolean) {
        if(value) {
            this.reset();
        }
    }

    // Lifecycle Methods:
    public mounted() {
        this.reset();
        if(this.userMailAddress) {
            this.additionalMailAddresses = [];
            this.additionalMailAddresses.push(this.userMailAddress);
        }
    }
    

    // Methods:
    public async save(): Promise<void> {
        if (!((this.$refs.form as Vue & { validate: () => boolean }).validate())) {
            return;
        }

        if(!this.ownerMailAddress) {
            return;
        }

        // Clone the existing query and modify its size and skip properites:
        const query = {...this.storedBrandQuery.query};
        query.size = this.selectedSize;
        query.skip = null;

        // Modify new object:
        this.busyStoredBrandQuery.object = {
            id: this.storedBrandQuery.id,
            query: query,
            name: this.name,
            ownerMailAddress: this.ownerMailAddress,
            description: this.description,
            notificationLanguage: this.selectedNotificationLanguage?.locale,
            agentExecutionFrequency: this.agentExecutionFrequency,
            additionalMailAddresses: this.selectedAdditionalMailAddresses
        };

        await this.busyStoredBrandQuery.create(async () => {
            if(!this.busyStoredBrandQuery.object) {
                return null;
            }
            return await StoredBrandQueriesService.upsertStoredBrandQueryCurrentUserDefaultAccount(this.busyStoredBrandQuery.object);
        });

        if(this.busyStoredBrandQuery.object) {
            this.fireStoredBrandQueryChanged(this.busyStoredBrandQuery.object);
        }

        this.close();
    }

    public removeMailAddress(item: string) {
        this.selectedAdditionalMailAddresses.splice(this.selectedAdditionalMailAddresses.indexOf(item), 1);
    }

    public mailAddressesValid(value: []): true | string {
        if(!this.agentExecutionFrequency) {
            return true;
        }

        if(!value) {
            return true;
        }
        
        for(const mail of value) {
            const validationResult = this.$validation.mail(mail as string, '');
            if(validationResult !== true) {
                return validationResult;
            }
        }
        
        return true;
    }

    public reset(): void {

        if(this.storedBrandQuery.id) {
            this.name = this.storedBrandQuery.name    
        }
        else {
            if(this.storedBrandQuery.name) {
                this.name = this.storedBrandQuery.name
            }
            else {
                this.name = this.defaultName;
            }
        }
        
        this.description = this.storedBrandQuery.description ?? null;

        this.selectedSize = this.storedBrandQuery.query.size ?? this.sizes[2];

        this.selectedNotificationLanguage = findLocale(this.storedBrandQuery.notificationLanguage) ?? findLocale(this.$i18n.locale) ?? findLocale('en-GB') ?? locales[0];

        this.ownerMailAddress = this.storedBrandQuery.ownerMailAddress ?? this.userMailAddress;

        if(this.storedBrandQuery.agentExecutionFrequency) {
            this.agentExecutionFrequency = this.storedBrandQuery.agentExecutionFrequency as AgentExecutionFrequency;
        }
        else {
            this.agentExecutionFrequency = null;
        }

        this.selectedAdditionalMailAddresses = this.storedBrandQuery.additionalMailAddresses ?? [];

        this.selectedTab = 0;
    }

    public close(): void {
        this.visible = false;
    }

    public fireVisibilityChanged(value: boolean): void {
        this.$emit('visibilityChanged', value);
    }

    public fireStoredBrandQueryChanged(value: StoredBrandQuery): void {
        this.$emit('storedBrandQueryChanged', value);
    }
}
